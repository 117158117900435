import React from "react";
import HeaderComponent from "./HtmlComponents/HeaderComponent";

export default class Header extends React.Component {
  constructor() {
    super();
  }
  render() {
    return <HeaderComponent />;
  }
}
