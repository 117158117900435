import React from "react";
import { AppBar, Container, Toolbar, Typography } from "@material-ui/core";
import "../../css/footer.css";

export default function FooterComponent() {
  return (
    <div className="footercomponent">
      <AppBar position="static" color="primary">
        <Container maxWidth="md">
          <Toolbar>
            <Typography variant="body1" color="inherit">
              Proiect de diploma – Februarie 2023
            </Typography>
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  );
}
