import React from "react";
import { MonthView } from "@devexpress/dx-react-scheduler-material-ui";
import AppointmentsTabs from "../tabs/AppointmentsTabs";

export default class AppointmentsForm extends React.Component {
  constructor() {
    super();

    this.state = {
      currentDate: new Date("2018-06-28"),
      appointments: [
        {
          title: "Website Re-Design Plan",
          startDate: new Date(2018, 5, 25, 9, 30),
          endDate: new Date(2018, 5, 25, 11, 30),
          id: 0,
        },
        {
          title: "Book Flights to San Fran for Sales Trip",
          startDate: new Date(2018, 5, 25, 12, 0),
          endDate: new Date(2018, 5, 25, 13, 0),
          id: 1,
        },
        {
          title: "New Brochures",
          startDate: new Date(2018, 5, 26, 14, 30),
          endDate: new Date(2018, 5, 26, 15, 45),
          id: 2,
        },
        {
          title: "Upgrade Personal Computers",
          startDate: new Date(2018, 5, 27, 0, 0),
          endDate: new Date(2018, 5, 27, 23, 59),
          allDay: true,
          id: 3,
        },
        {
          title: "Customer Workshop",
          startDate: new Date(2018, 5, 28, 11, 0),
          endDate: new Date(2018, 5, 28, 12, 0),
          id: 4,
        },
        {
          title: "Prepare 2015 Marketing Plan",
          startDate: new Date(2018, 5, 28, 11, 0),
          endDate: new Date(2018, 5, 28, 13, 30),
          id: 5,
        },
        {
          title: "Create Icons for Website",
          startDate: new Date(2018, 5, 29, 10, 0),
          endDate: new Date(2018, 5, 29, 11, 30),
          id: 6,
        },
        {
          title: "Launch New Website",
          startDate: new Date(2018, 5, 29, 12, 20),
          endDate: new Date(2018, 5, 29, 14, 0),
          id: 7,
        },
        {
          title: "Website Re-Design Plan",
          startDate: new Date(2018, 6, 2, 9, 30),
          endDate: new Date(2018, 6, 2, 15, 30),
          id: 8,
        },
        {
          title: "Book Flights to San Fran for Sales Trip",
          startDate: new Date(2018, 6, 2, 12, 0),
          endDate: new Date(2018, 6, 2, 13, 0),
          id: 9,
        },
        {
          title: "Install New Router in Dev Room",
          startDate: new Date(2018, 6, 2, 14, 30),
          endDate: new Date(2018, 6, 2, 17, 30),
          id: 10,
        },
        {
          title: "Approve Personal Computer Upgrade Plan",
          startDate: new Date(2018, 6, 2, 16, 0),
          endDate: new Date(2018, 6, 3, 9, 0),
          id: 11,
        },
        {
          title: "New Brochures",
          startDate: new Date(2018, 6, 3, 14, 30),
          endDate: new Date(2018, 6, 3, 15, 45),
          id: 12,
        },
        {
          title: "Brochure Design Review",
          startDate: new Date(2018, 6, 6, 14, 10),
          endDate: new Date(2018, 6, 6, 15, 30),
          id: 13,
        },
        {
          title: "Create Icons for Website",
          startDate: new Date(2018, 6, 6, 10, 0),
          endDate: new Date(2018, 6, 7, 14, 30),
          id: 14,
        },
        {
          title: "Upgrade Server Hardware",
          startDate: new Date(2018, 6, 3, 9, 30),
          endDate: new Date(2018, 6, 3, 12, 25),
          id: 15,
        },
        {
          title: "Submit New Website Design",
          startDate: new Date(2018, 6, 3, 12, 30),
          endDate: new Date(2018, 6, 3, 18, 0),
          id: 16,
        },
      ],
    };
  }

  TimeTableCell = (props, handleTabChange) => {
    return (
      <MonthView.TimeTableCell
        {...props}
        onClick={(e) => {
          const dayHtmlElement =
            e.target.tagName === "DIV"
              ? e.target
              : e.target.getElementsByTagName("div")[0];
          const dayInnerHTML = dayHtmlElement.innerHTML;
          const day =
            dayInnerHTML.length > 2
              ? new Date(dayInnerHTML).getDate()
              : dayInnerHTML;
          const { currentDate } = this.state;
          handleTabChange(e, 1);
          this.setState({
            currentDate: new Date(
              currentDate.getFullYear(),
              currentDate.getMonth(),
              day,
              currentDate.getHours(),
              currentDate.getMinutes()
            ),
          });
        }}
      />
    );
  };

  render() {
    const { appointments, currentDate } = this.state;
    return (
      <React.Fragment>
        <form className="formcomponent">
          <br />
          <AppointmentsTabs
            appointments={appointments}
            currentDate={currentDate}
            TimeTableCell={this.TimeTableCell}
          />
          <br />
        </form>
      </React.Fragment>
    );
  }
}
