import React from "react";
import "../../css/header.css";
import { Nav, Navbar } from "react-bootstrap";
import { ReactComponent as Logo } from "../../media/logo.svg";
import "bootstrap/dist/css/bootstrap.min.css";

export default function HeaderComponent(props) {
  return (
    <div className="headercomponent">
      <Navbar collapseOnSelect expand="sm" bg="dark" variant="dark">
        <Navbar.Brand href="/">
          <Logo
            alt=""
            width="35"
            height="35"
            className="d-inline-block align-top"
          />
          Medical App
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto">
            <Nav.Link href="/features">Features</Nav.Link>
            <Nav.Link href="/pricing">Pricing</Nav.Link>
          </Nav>
          <Nav>
            <Nav.Link href="/login">Log in</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
}
