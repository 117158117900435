import React from "react";
import Header from "./common/Header";
import Footer from "./common/Footer";
import AppointmentsForm from "./forms/AppointmentsForm";
//import LoginForm from "./forms/LoginForm";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/core";
import {
  amber,
  cyan,
  indigo,
  pink,
  lightGreen,
  deepOrange,
} from "@material-ui/core/colors";
const theme = createTheme({
  palette: {
    primary: amber,
    mode: "light",
  },
});

function App() {
  return (
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <div className="App">
          <header>
            <Header />
          </header>
          <main>
            <AppointmentsForm />
          </main>
          <footer>
            <Footer />
          </footer>
        </div>
      </ThemeProvider>
    </React.StrictMode>
  );
}

export default App;
