import React from "react";
import { AppBar, Tabs, Tab } from "@material-ui/core";
import TabPanel from "../tabs/TabPanel";
import PropTypes from "prop-types";
import { ViewState } from "@devexpress/dx-react-scheduler";
import {
  Scheduler,
  DayView,
  MonthView,
  Appointments,
  AllDayPanel,
} from "@devexpress/dx-react-scheduler-material-ui";

const a11yProps = (index) => {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
};

export default function AppointmentsTabPanel(props) {
  const { appointments, currentDate, TimeTableCell } = props;
  const [selectedTabValue, setSelectedTab] = React.useState(0);
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <React.Fragment>
      <AppBar position="static" style={{ background: "#e0e0e0" }}>
        <Tabs
          value={selectedTabValue}
          onChange={handleTabChange}
          aria-label="appointments tabs"
          TabIndicatorProps={{
            style: { background: "#757575" },
          }}
          textColor="primary"
        >
          <Tab label="Item One" {...a11yProps(0)} />
          <Tab label="Item Two" {...a11yProps(1)} />
        </Tabs>
      </AppBar>
      <TabPanel value={selectedTabValue} index={0}>
        <Scheduler data={appointments}>
          <ViewState currentDate={currentDate} />
          <MonthView
            timeTableCellComponent={(props) =>
              TimeTableCell(props, handleTabChange)
            }
          />
          <AllDayPanel />
          <Appointments />
        </Scheduler>
      </TabPanel>
      <TabPanel value={selectedTabValue} index={1}>
        <Scheduler data={appointments}>
          <ViewState currentDate={currentDate} />
          <DayView startDayHour={7.5} endDayHour={17.5} />
          <AllDayPanel />
          <Appointments />
        </Scheduler>
      </TabPanel>
    </React.Fragment>
  );
}

AppointmentsTabPanel.propTypes = {
  appointments: PropTypes.array.isRequired,
  currentDate: PropTypes.instanceOf(Date).isRequired,
  TimeTableCell: PropTypes.any.isRequired,
};
